import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
//
import Logo from '../logo';
import backgroundImage from '../../assets/project-assets/bg-splash.png';
import logo from '../../assets/project-assets/logo2.png';
import Image from '../image';
// ----------------------------------------------------------------------

export default function SplashScreen({ sx, ...other }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        backgroundImage: `url(${backgroundImage})`, // Set background image here
        // backgroundSize: '100% 100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents repeating the image
        zIndex: 9998,
        position: 'fixed',
      }}
    >
      <Box
        sx={{
          height: 1,
          width: 1,
          display: 'flex',
          position: 'fixed',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img height="100" alt="logo" src={logo} />
        {/* <Image height="100" src={logo} alt="logo" /> */}
      </Box>
      {/* <Box
        sx={{
          right: 0,
          width: 1,
          bottom: 0,
          height: 1,
          display: 'flex',
          position: 'fixed',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          ...sx,
        }}
        {...other}
      >
        <>
          <m.div
            animate={{
              scale: [1, 0.9, 0.9, 1, 1],
              opacity: [1, 0.48, 0.48, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <Logo disabledLink sx={{ width: 64, height: 64 }} />
          </m.div>

          <Box
            component={m.div}
            animate={{
              scale: [1.6, 1, 1, 1.6, 1.6],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              position: 'absolute',
              border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />

          <Box
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 120,
              height: 120,
              position: 'absolute',
              border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />
        </>
      </Box> */}
    </Box>
  );
}

SplashScreen.propTypes = {
  sx: PropTypes.object,
  backgroundImage: PropTypes.string, // Add PropType for the background image
};
