import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });
  console.log(res);
  if (res.status !== 200) {
    return { error: true, status: res.status };
  }
  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/auth',
    login: '/auth/admin/login',
    register: '/auth/register',
    forgotPassword: '/auth/admin/user/forget-password',
    newPassword: '/auth/admin/user/reset-password',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  role: {
    list: '/admin/roles',
    create: '/admin/roles/create',
    update: '/admin/roles/update-role',
    delete: '/admin/roles/delete-role',
    permissions: '/admin/permissions/get-permission',
    updatePermissions: '/admin/roles/assign-permissions',
  },
  company: {
    list: '/admin/companies',
    create: '/admin/company/create',
    update: 'admin/company/update/profile',
    delete: '/admin/company/:id',
    deletedCompanies: '/admin/deleted-companies',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  user: {
    list: '/admin/users',
    create: '/auth/admin/user/register',
    update: '/auth/admin/user/update',
    changeStatus: '/auth/admin/user/change-status',
    delete: '/auth/admin/user/:id',
  },
};
